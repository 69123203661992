* {
    margin: 0;
    padding: 0;
    text-decoration: none;
}
html {
    a:hover {
        opacity: 0.5;
    }
}
